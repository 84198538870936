<template>
  <div class="participate-parent">
    <section class="participate" id="delta">
      <div v-if="!submitted">
        <h2>
          {{ formTitle }}
        </h2>

        <p>
          <br />
          {{ formText }}
          <br />
          <br />
        </p>

        <p class="participate__highlight">{{ formCallout }}</p>

        <form
          @submit.prevent="submit"
          class="participate__form"
          data-netlify="true"
          name="matvarehuset"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="matvarehuset" />

          <div class="participate__form-inputs">
            <InputComponent
              placeholder="Kari Nordman"
              required
              v-model="data.name"
              :is-valid="validator.fields.name.valid"
              name="name"
              label="Navn"
            >
            </InputComponent>

            <InputComponent
              placeholder="123 45 678"
              required
              v-model="data.phone"
              name="phone"
              :is-valid="validator.fields.phone.valid"
              label="Telefon"
            >
            </InputComponent>

            <InputComponent
              placeholder="navn@gmail.com"
              v-model="data.email"
              name="email"
              class="form-email"
              label="E-post"
            >
            </InputComponent>

            <div class="participate__flex">
              <label class="participate__form-label" for="kommentar"
                >Kommentar</label
              >

              <textarea
                class="participate__form-comment"
                id="kommentar"
                name="comment"
                rows="4"
                v-model="data.comment"
                placeholder="Valgfri kommentar"
              ></textarea>
            </div>
          </div>

          <CheckboxComponent required v-model="privacyAccepted">
            <p class="participate__terms-font">
              <span class="participate__terms-span">
                Jeg ønsker å bli kontaktet i samtykke med
                <router-link to="/terms">Personverklæringen</router-link>.
              </span>
            </p>
          </CheckboxComponent>

          <div class="participate__required">
            {{ formInfo }}
          </div>

          <div class="participate__buttons">
            <ButtonComponent
              :label="formButton"
              theme="primary"
              success-label="Fullført"
              type="submit"
              :promise="promise"
              error-label="NOE GIKK GALT.."
              loading-label="Laster..."
              :disabled="!validator.passes || !privacyAccepted"
              class="participate__button"
              :icon="['fas', 'arrow-right']"
            />
          </div>

          <div class="participate__contactinfo">
            <p>
              {{ formContactInfo1 }}
              <br />
              {{ formContactInfo2 }}
              <br />
              {{ formContactInfo3 }}
            </p>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Validator from "@/mixins/validator";
import { CheckboxComponent } from "vue-elder-checkbox";
import { InputComponent } from "vue-elder-input";
import axios from "axios";

import Message from "@/components/Message.vue";

import { Clone } from "@/utils";

const template = {
  name: null,
  phone: null,
  email: null,
  comment: null,
};

export default {
  mixins: [Validator],
  data() {
    return {
      data: Clone(template),
      privacyAccepted: null,
      promise: null,
      disableCodePopover: false,
      submitted: false,
      validator: {
        rules: {
          name: "required",
          phone: "required|phone",
        },
        labels: "labels",
        data: "data",
      },
    };
  },
  computed: {
    labels() {
      return {
        name: "Navn*",
        email: "E-post",
        phone: "Telefon*",
        comment: "Kommentar",
      };
    },
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async submit() {
      if (!this.validator.passes || !this.privacyAccepted) return;

      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      axios
        .post(
          "/",
          this.encode({
            "form-name": "matvarehuset",
            ...this.data,
          }),
          axiosConfig
        )
        .then(() => {
          this.$router.push("/thank-you");
        })
        .catch(() => {
          this.$router.push("/error");
        });
    },
    reset() {
      this.data = Clone(template);
      this.privacyAccepted = null;
    },
  },
  components: {
    CheckboxComponent,
    InputComponent,
    Message,
  },
  props: {
    formTitle: String,
    formText: String,
    formCallout: String,
    formInfo: String,
    formButton: String,
    formContactInfo1: String,
    formContactInfo2: String,
    formContactInfo3: String,
  },
};
</script>

<style lang="scss">
.participate-parent {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.participate {
  padding-top: 7.5rem;
  padding-bottom: 3rem;
  text-align: center;
  color: var(--primary-contrast);
  width: 100%;
  max-width: 800px;
  margin: auto;

  @media (max-width: $mobile) {
    width: 95%;
    padding-bottom: 3rem;
  }

  &__highlight {
    font-size: 1.75rem;
    font-weight: 500;
  }

  span {
    font-family: var(--paragraph-font);

    @media (max-width: $mobile) {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  &__form {
    position: relative;

    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;

    @media (max-width: $mobile) {
      max-width: 100%;
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__required {
    text-align: left;

    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__contactinfo {
    display: flex;
    justify-content: start;

    text-align: start;

    margin-top: 2rem;
  }

  .elder-checkbox {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: left;

    align-self: center;
    align-items: center;

    &--checked {
      .elder-checkbox__box {
        background: var(--primary);
        border-color: var(--primary);
      }
    }

    &__label {
      font-weight: 200;

      a {
        text-decoration: underline;
        color: #000000;
      }
    }

    &__box {
      margin-right: 1rem;
      background: white;
      border-color: #000000;
      border-width: 1px;
      border-radius: 50px;
    }
    &__required {
      font-weight: bold;
      margin-left: 2px;
      color: var(--primary);
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;
  }

  &__form-label {
    font-family: var(--paragraph-font);
    font-weight: 500;
    font-size: 1.25rem;
    text-align: left;
  }

  &__form-comment {
    padding: 1rem;
    border: 1px solid var(--vue-elder-border-color, #000);
    border-radius: 10px;
    background-color: white;
    margin-top: 0.5em;
  }

  .elder-input {
    &__label {
      font-family: var(--paragraph-font);
      font-weight: 500;
      font-size: 1.25rem;
    }

    &__element {
      padding: 1rem;
    }

    &__field {
      border-color: #000000;
      border-radius: 10px;

      &--invalid {
        border-color: var(--primary) !important;
      }
    }

    &__validation {
      display: none;
    }

    &__validation-message {
      p {
        color: var(--primary);
      }

      position: absolute;
      left: 50%;
      top: 45%;

      transform: translate(-50%, -50%);

      color: white;
      background: var(--secondary);
      padding: 5px;
      width: 100%;
      font-weight: 700;
      text-align: center;
      font-size: 20px;
      z-index: 10;
      border-radius: var(--vue-elder-border-radius);
      box-shadow: var(--box-shadow);
      z-index: -1;
      visibility: hidden;

      @keyframes showError {
        0% {
          overflow: hidden;
          visibility: visible;
          z-index: 1;
        }
        99% {
          overflow: hidden;
          visibility: hidden;
          z-index: 1;
        }
        100% {
          overflow: hidden;
          visibility: hidden;
          z-index: -1;
        }
      }

      animation-name: showError;
      animation-duration: 4s;

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        width: 30px;
        height: 30px;
        path {
          fill: white;
        }
      }
    }
  }

  // .v-popover {
  //   // text-align: right;
  //   margin: 0.3rem 0;
  //   // margin-right: 1.5rem;
  //   .trigger {
  //     display: flex !important;
  //     justify-content: space-between;
  //     padding: 10px 30px;

  //     @media (max-width: $mobile) {
  //       flex-direction: column;
  //       gap: 15px;
  //     }

  //     &:hover {
  //       cursor: pointer;
  //     }
  //   }
  // }

  &__terms-font {
    font-size: 0.9rem;
    display: inline;
  }

  &__terms-span {
    line-height: 24px;
  }

  h1 {
    color: var(--primary-contrast);
  }

  h2 {
    color: var(--primary-contrast);
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }

  &__buttons {
    display: flex;
    justify-content: start;
  }

  &__button {
    background-color: var(--primary);
    color: var(--secondary-contrast);

    @media (max-width: $mobile) {
      span {
        font-size: 1.25rem;
      }
    }
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
</style>
