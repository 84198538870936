<template>
  <div class="container">
    <HeroSection
      :heroTitle="heroTitle"
      :heroText="heroText"
      :heroImage="heroImage"
    />

    <Prices
      :pricingTitle="pricingTitle"
      :seeMoreButton="seeMoreButton"
      :seeLessButton="seeLessButton"
      :pricingTextOne="pricingTextOne"
      :pricingSubtitleOne="pricingSubtitleOne"
      :pricingSubtitleTwo="pricingSubtitleTwo"
      :pricingTextTwo="pricingTextTwo"
      :pricingSubtitleThree="pricingSubtitleThree"
      :pricingTextThree="pricingTextThree"
      :pricingTextFour="pricingTextFour"
      :pricingTileOneTitle="pricingTileOneTitle"
      :pricingTileOneSubtitle="pricingTileOneSubtitle"
      :pricingTileOnePrice="pricingTileOnePrice"
      :pricingTileOneDescription="pricingTileOneDescription"
      :pricingTileCallout="pricingTileCallout"
      :pricingTileTwoTitle="pricingTileTwoTitle"
      :pricingTileTwoSubtitle="pricingTileTwoSubtitle"
      :pricingTileTwoPrice="pricingTileTwoPrice"
      :pricingTileTwoDescription="pricingTileTwoDescription"
      :pricingTileThreeTitle="pricingTileThreeTitle"
      :pricingTileThreeSubtitle="pricingTileThreeSubtitle"
      :pricingTileThreePrice="pricingTileThreePrice"
      :pricingTileThreeDescription="pricingTileThreeDescription"
      :pricingTileFourTitle="pricingTileFourTitle"
      :pricingTileFourPrice="pricingTileFourPrice"
      :pricingTileFiveTitle="pricingTileFiveTitle"
      :pricingTileFivePrice="pricingTileFivePrice"
      :pricingTileSixTitle="pricingTileSixTitle"
      :pricingTileSixPrice="pricingTileSixPrice"
      :pricingTileSixDescription="pricingTileSixDescription"
      :pricingTileSevenTitle="pricingTileSevenTitle"
      :pricingTileSevenPrice="pricingTileSevenPrice"
      :pricingTileSevenDescription="pricingTileSevenDescription"
      :pricingTileEightTitle="pricingTileEightTitle"
      :pricingTileEightPrice="pricingTileEightPrice"
      :pricingTileEightDescription="pricingTileEightDescription"
      :pricingTileNineTitle="pricingTileNineTitle"
      :pricingTileNinePrice="pricingTileNinePrice"
      :pricingTileTenTitle="pricingTileTenTitle"
      :pricingTileTenPrice="pricingTileTenPrice"
      :pricingTileElevenTitle="pricingTileElevenTitle"
      :pricingTileElevenPrice="pricingTileElevenPrice"
      :registerPreText="registerPreText"
      :registerTitle="registerTitle"
      :registerPostText="registerPostText"
      :foodImage1="foodImage1"
      :foodImage2="foodImage2"
    />

    <Carousel
      :carouselSlideOneTitle="carouselSlideOneTitle"
      :carouselSlideOneSubtitle="carouselSlideOneSubtitle"
      :carouselSlideTwoTitle="carouselSlideTwoTitle"
      :carouselSlideTwoSubtitle="carouselSlideTwoSubtitle"
      :carouselSlideThreeTitle="carouselSlideThreeTitle"
      :carouselSlideThreeSubtitle="carouselSlideThreeSubtitle"
      :carouselImage1="carouselImage1"
      :carouselImage2="carouselImage2"
      :carouselImage3="carouselImage3"
    />

    <USPS
      :benefitTitle="benefitTitle"
      :benefit1="benefit1"
      :benefit2="benefit2"
      :benefit3="benefit3"
      :benefit4="benefit4"
      :benefit5="benefit5"
      :benefit6="benefit6"
      :benefit7="benefit7"
      :benefit8="benefit8"
    />

    <Form
      :formTitle="formTitle"
      :formText="formText"
      :formCallout="formCallout"
      :formInfo="formInfo"
      :formButton="formButton"
      :formContactInfo1="formContactInfo1"
      :formContactInfo2="formContactInfo2"
      :formContactInfo3="formContactInfo3"
    />

    <Aboutus
      :registerPreText="registerPreText"
      :registerTitle="registerTitle"
      :registerPostText="registerPostText"
      :aboutTitle="aboutTitle"
      :aboutSection1Title="aboutSection1Title"
      :aboutSection1Text="aboutSection1Text"
      :aboutSection2Title="aboutSection2Title"
      :aboutSection2Text="aboutSection2Text"
      :aboutSection3Title="aboutSection3Title"
      :aboutSection3Text="aboutSection3Text"
      :aboutSection4Title="aboutSection4Title"
      :aboutSection4Text="aboutSection4Text"
      :faqLinkTitle="faqLinkTitle"
      :faqLinkSubtitle="faqLinkSubtitle"
      :faqLinkButton="faqLinkButton"
      :aboutImage1="aboutImage1"
      :aboutImage2="aboutImage2"
      :aboutImage3="aboutImage3"
      :aboutImage4="aboutImage4"
    />
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import Form from "@/components/Form.vue";
import Prices from "@/components/Prices.vue";
import USPS from "@/components/USPS.vue";
import Aboutus from "@/components/Aboutus.vue";
import Carousel from "@/components/Carousel.vue";

export default {
  components: {
    HeroSection,
    Form,
    Prices,
    USPS,
    Aboutus,
    Carousel,
  },
  props: {
    heroText: String,
    heroTitle: String,
    heroImage: String,
    isExpanded: String,
    pricingTitle: String,
    seeMoreButton: String,
    seeLessButton: String,
    pricingTextOne: String,
    pricingSubtitleOne: String,
    pricingSubtitleTwo: String,
    pricingTextTwo: String,
    pricingSubtitleThree: String,
    pricingTextThree: String,
    pricingTextFour: String,
    pricingTileOneTitle: String,
    pricingTileOneSubtitle: String,
    pricingTileOnePrice: String,
    pricingTileOneDescription: String,
    pricingTileCallout: String,
    pricingTileTwoTitle: String,
    pricingTileTwoSubtitle: String,
    pricingTileTwoPrice: String,
    pricingTileTwoDescription: String,
    pricingTileThreeTitle: String,
    pricingTileThreeSubtitle: String,
    pricingTileThreePrice: String,
    pricingTileThreeDescription: String,
    pricingTileFourTitle: String,
    pricingTileFourPrice: String,
    pricingTileFiveTitle: String,
    pricingTileFivePrice: String,
    pricingTileSixTitle: String,
    pricingTileSixPrice: String,
    pricingTileSixDescription: String,
    pricingTileSevenTitle: String,
    pricingTileSevenPrice: String,
    pricingTileSevenDescription: String,
    pricingTileEightTitle: String,
    pricingTileEightPrice: String,
    pricingTileEightDescription: String,
    pricingTileNineTitle: String,
    pricingTileNinePrice: String,
    pricingTileTenTitle: String,
    pricingTileTenPrice: String,
    pricingTileElevenTitle: String,
    pricingTileElevenPrice: String,
    foodImage1: String,
    foodImage2: String,
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
    carouselSlideOneTitle: String,
    carouselSlideOneSubtitle: String,
    carouselSlideTwoTitle: String,
    carouselSlideTwoSubtitle: String,
    carouselSlideThreeTitle: String,
    carouselSlideThreeSubtitle: String,
    carouselImage1: String,
    carouselImage2: String,
    carouselImage3: String,
    benefitTitle: String,
    benefit1: String,
    benefit2: String,
    benefit3: String,
    benefit4: String,
    benefit5: String,
    benefit6: String,
    benefit7: String,
    benefit8: String,
    aboutTitle: String,
    aboutSection1Title: String,
    aboutSection1Text: String,
    aboutSection2Title: String,
    aboutSection2Text: String,
    aboutSection3Title: String,
    aboutSection3Text: String,
    aboutSection4Title: String,
    aboutSection4Text: String,
    aboutImage1: String,
    aboutImage2: String,
    aboutImage3: String,
    aboutImage4: String,
    faqLinkTitle: String,
    faqLinkSubtitle: String,
    faqLinkButton: String,
    formTitle: String,
    formText: String,
    formCallout: String,
    formInfo: String,
    formButton: String,
    formContactInfo1: String,
    formContactInfo2: String,
    formContactInfo3: String,
  },
};
</script>

<style lang="scss"></style>
