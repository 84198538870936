<template>
  <nav class="navigation" :class="{ 'hide-navbar': hideNavbar }">
    <div class="navigation__elements" :class="alignmentClass">
      <a href="https://matvarehuset.no/" class="navigation__logo">
        <img :src="logo" />
      </a>

      <div
        class="navigation__elements__links"
        v-for="(item, index) in navItems"
        :key="index"
        :class="'index-' + index"
      >
        <span class="navigation__elements__labels">
          <a href="#" @click.prevent="item.action">{{ item.label }}</a>
          <div v-if="index === 4" class="navigation__arrow-icon"></div>
        </span>
      </div>

      <div
        @click="burgermenuOpen = !burgermenuOpen"
        class="navigation__elements__burger"
      >
        <div class="navigation__menu-mobile">
          <span>
            MENY
          </span>
        </div>

        <div v-if="!burgermenuOpen" class="navigation__elements__burger-parent">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 448 512"
            fill="#d64431"
          >
            <path
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
            />
          </svg>
        </div>

        <div class="navigation__elements__burger__x" v-if="burgermenuOpen">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 384 512"
            fill="#d64431"
          >
            <path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            />
          </svg>
        </div>
      </div>

      <div v-show="burgermenuOpen" class="navigation__elements__burger-overlay">
        <div
          class="navigation__elements__burger-overlay__links"
          v-for="(item, index) in navItems"
          :key="index"
          :class="'index-' + index"
        >
          <!-- <span class="navigation__elements__labels">
            <router-link @click="burgermenuOpen = false" :to="item.link">{{
              item.label
            }}</router-link>
          </span> -->

          <span
            class="navigation__elements__labels"
            :class="{ 'mobile-button': index === 4 }"
          >
            <a
              @click="burgermenuOpen = false"
              href="#"
              @click.prevent="item.action"
              >{{ item.label }}</a
            >
            <div v-if="index === 4" class="orange-button__arrow-icon "></div>
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { redirect } from "@/utils/index.js";

export default {
  props: {
    alignment: String,
    navItems: Array,
    logo: String,
  },
  data() {
    return {
      hideNavbar: false,
      prevScrollPos: 0,
      burgermenuOpen: false,
    };
  },
  computed: {
    alignmentClass() {
      if (this.alignment === "right") return "navigation__elements__right";
      if (this.alignment === "center") return "navigation__elements__center";
      return "";
    },
  },
  methods: {
    handleScroll() {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos <= 1) this.hideNavbar = false;
      else this.hideNavbar = true;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.navigation {
  //   position: sticky;
  //   top: 0;
  //   z-index: 999;

  position: fixed;
  z-index: 100;
  width: 100%;

  padding-top: 7.5px;
  padding-left: 10%;
  padding-right: 5%;

  transition: 0.3s;
  transform: translate3d(0, 0, 0);

  @media (max-width: $tablet) {
    background-color: var(--secondary);
    padding: 10px 0px 10px 20px;
  }

  &__logo {
    position: relative;
    z-index: 1;
    margin-right: 12vw;
  }

  &__menu-mobile {
    @media (min-width: $tablet) {
      display: none;
    }

    span {
      color: var(--primary);
      font-size: 17px;
      line-height: 20.4px;
      font-weight: 600;
    }
  }

  &__elements {
    // background-color: var(--secondary);

    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5vw;
    white-space: nowrap;

    @media (max-width: $tablet) {
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 35px;
      gap: 0px;
    }

    &__labels {
      color: var(--primary-contrast);
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__right {
      @media (min-width: $tablet) {
        .logo {
          margin-right: 0;
          margin-left: 150px;
          order: 1;
        }
      }
    }

    &__center {
      @media (min-width: $tablet) {
        margin: 0;

        .logo {
          margin: 0 20px;
        }

        .index-0 {
          order: 0;
        }

        .index-1 {
          order: 2;
        }

        .logo {
          order: 1;
        }
      }
    }

    &__links {
      @media (max-width: $tablet) {
        display: none;
      }

      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--primary);
        text-decoration-thickness: 2px;
        text-underline-offset: 10px;
      }
    }

    &__burger-parent {
      display: flex;
      align-items: center;
    }

    &__burger {
      position: relative;
      z-index: 1;
      height: 100%;
      cursor: pointer;

      display: flex;
      align-items: center;
      gap: 17.5px;

      svg {
        z-index: 2;
      }

      @media (min-width: $tablet) {
        display: none;
      }

      &__x {
        margin-right: 2px;
      }
    }

    &__burger-overlay {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;

      background-color: var(--secondary);
      position: fixed;
      width: 100vw;
      height: 150vh;

      overflow-y: hidden;
      top: -50px;
      padding-top: 200px;
      left: 0;

      .links {
        text-align: center;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    height: 100px;

    @media (max-width: $tablet) {
      height: 87px;
    }
  }

  &__arrow-icon {
    background-image: url("@/assets/elements/arrow-right.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 16px;
    height: 16px;
  }
}

.hide-navbar {
  //   visibility: hidden;
  //   transition: 0.3s;
  //   transform: translate3d(0, -100%, 0);

  background-color: var(--secondary);
}

.index-4 {
  @media (min-width: $tablet) {
    background-color: var(--primary);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    outline: 2px solid transparent;
    outline-offset: -2px;

    span {
      font-family: var(--primary-font);
      color: var(--secondary-contrast);
      text-decoration: none !important;
    }
  }
}

.mobile-button {
  background-color: var(--primary);
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  outline: 2px solid transparent;
  outline-offset: -2px;

  span {
    font-family: var(--primary-font);
    color: var(--secondary-contrast);
    text-decoration: none !important;
  }

  a {
    color: white;
  }
}
</style>
