<template>
  <div class="USPS-parent" id="usps">
    <h2>{{ benefitTitle }}</h2>

    <div class="USPS">
      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__icon"
            src="@/assets/elements/cutlery.png"
            alt="Ikon"
          />
        </div>
        <p>{{ benefit1 }}</p>
      </div>

      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__icon"
            src="@/assets/elements/bowl-hot.png"
            alt="Ikon"
          />
        </div>
        <p>
          {{ benefit2 }}
        </p>
      </div>

      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__icon"
            src="@/assets/elements/user-chef.png"
            alt="Ikon"
          />
        </div>
        <p>{{ benefit3 }}</p>
      </div>

      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__icon"
            src="@/assets/elements/clock.png"
            alt="Ikon"
          />
        </div>
        <p>{{ benefit4 }}</p>
      </div>

      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__credit-card"
            src="@/assets/elements/credit-card.png"
            alt="Ikon"
          />
        </div>
        <p>{{ benefit5 }}</p>
      </div>

      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__icon"
            src="@/assets/elements/sliders.png"
            alt="Ikon"
          />
        </div>
        <p>{{ benefit6 }}</p>
      </div>

      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__award"
            src="@/assets/elements/award.png"
            alt="Ikon"
          />
        </div>
        <p>{{ benefit7 }}</p>
      </div>

      <div class="USPS__element">
        <div class="USPS__circle">
          <img
            class="USPS__icon"
            src="@/assets/elements/smile.png"
            alt="Ikon"
          />
        </div>
        <p>{{ benefit8 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    benefitTitle: String,
    benefit1: String,
    benefit2: String,
    benefit3: String,
    benefit4: String,
    benefit5: String,
    benefit6: String,
    benefit7: String,
    benefit8: String,
  },
};
</script>

<style lang="scss">
.USPS-parent {
  margin: auto;
  text-align: center;
  padding-top: 7.5rem;
}

.USPS {
  margin: auto;
  margin-top: 3rem;

  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 2rem;

  width: 100%;
  max-width: 1100px;

  @media (max-width: $tablet) {
    justify-content: center;
    max-width: 800px;
  }

  &__element {
    width: 250px;

    p {
      text-align: center;
      margin-top: 1rem;
      font-size: 1.25rem;
    }

    @media (max-width: $tablet) {
      width: 165px;
    }
  }

  &__circle {
    margin: auto;

    width: 100px;
    height: 100px;
    background-color: var(--primary);
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 50px;
    height: 50px;
  }

  &__credit-card {
    width: 57px;
    height: 50px;
  }

  &__award {
    width: 38px;
    height: 50px;
  }
}
</style>
